import { Row, Col, Collapse, Descriptions, Divider, Timeline, Form, Checkbox, Button, Input, Badge, List, Comment, Avatar, Drawer, Alert, Popover } from "antd";
import { LoadingOutlined, ClockCircleOutlined, ExclamationCircleOutlined, CloseCircleOutlined, CheckCircleOutlined } from "@ant-design/icons"
import "../Assets/css/App.css";
import Banner from "../components/Banner/Banner";
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs";
import { useNavigate, useParams } from "react-router-dom";
import BaseLayout from "./baselayout";
import { useEffect, useMemo, useState } from "react";
import * as api from "../api";
import Header from "../components/Header/Header";
import AkamaiPreloader from "../components/AkamaiPreloader/AkamaiPreloader"
import { useGetTodoQuery, useGetIssueActivityQuery, useAddResolutionMutation, useGetFTNotesQuery, useInsertFTNotesMutation, useLazyGetTrackingHistoryQuery } from "../services/datacenter";
import { useRunIsOkMutation, useGetIsOkMutation, useLazyAttachIsOkResultQuery } from "../services/isok";
import { useGetTicketOrdersQuery } from "../services/clientutils";
import DataTables from "../components/DataTables/DataTables";
import moment from "moment";
import Marquee from 'react-fast-marquee';

const { Panel } = Collapse;
let timer = null;
const userDetails = api.getUserDetails();

function TicketDetailsWrapper({ type, isImpersonated }) {

    const { data_center_id, ticket_id } = useParams();
    const [errorMsg, setErrormsg ] =useState(null);
    const [todoSelected, setTodoSelected] = useState({});
    const [showCollapse, setShowCollapse] = useState(true);
    const [resolutionSpinner, startResolutionSpinner] = useState(null);
    const [isokSpinner, startIsOkSpinner] = useState(null);
    const [testResult, setTestResult] = useState({value: ''});
    const [childDrawerNotesVisible, setChildDrawerNotesVisible] = useState(false);
    const [commentDisabled, setCommentDisabled] = useState(true);
    const [rmaOrders, setRMAOrders] = useState([]);
    const [soOrders, setSOOrders] = useState([]);
    const [unAuthorised, setUnAuthorised] = useState(false);
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [selectedTrackingNumber, setSelectedTrackingNumber] = useState(null)

    const [formsubmit] = Form.useForm();
    const [formNotes] = Form.useForm();

    const [triggerIsOk, triggerIsOkFunc] = useRunIsOkMutation();
    const [triggerGetResolution, getResolutionFunc] = useGetIsOkMutation();
    const [triggerAddResolution, {error: addResolutionError}] = useAddResolutionMutation();
    const [triggerAddFTNotes, addFTNotes] = useInsertFTNotesMutation();

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const safeBadge = `This Ticket ${todoSelected.is_safe === "Unknown Ask" ? " may not be safe to work on." : " is not safe to work on."} Please contact NIE to determine whether it is safe to proceed.`
    let isokResponseData = null;

    api.setLastPath(window.location.href);

    const { data:issue, error, refetch, isFetching } = useGetTodoQuery({
      data_center_id,
      ticket_id,
    });

    const {
      data: ticketOrders,
      error: ticketOrdersError,
      isFetching: isTicketOrdersFetching
    } = useGetTicketOrdersQuery({ticket_id}, {skip: Object.keys(todoSelected).length === 0})

    const {
        data: historyDetails,
        error: historyError,
        isLoading: isHistoryLoading,
        isFetching: isHistoryFetching,
        isSuccess: isHistorySuccess,
        refetch: refetchHistory
      } = useGetIssueActivityQuery({ limit:10, data: {data: [{"issue_id": ticket_id}]}, node_type:["Todo","Resolution"] }, { skip: Object.keys(todoSelected).length === 0});

    const {
        data: FTNotes,
        error: FTNotesError,
        isLoading: isFTNotesLoading,
        refetch: refetchNotes,
        isFetching: isFTNotesFetching,
        isSuccess: isFTNotesSuccess,
      } = useGetFTNotesQuery({ticket_id:todoSelected['issue_id']}, {skip: type === "rmas" || (Object.keys(todoSelected).length === 0) });

    const [triggerGetTrackingHistory, {data: trackingHistory, error: trackingHistoryError, isFetching: trackingHistoryIsFetching }] = useLazyGetTrackingHistoryQuery()

    const [triggerGetIsOkAttachment, {data:isOkAttachment, error:isOkAttachmentError, isFetching:isOkAttachmentFetching}] = useLazyAttachIsOkResultQuery();

    const fetchIsOkResultAttached = (issue_id) =>{
      triggerGetIsOkAttachment({payload:{ticket_id:issue_id}})
    }
  
    // check for unauthorized access, return to /datacenters if unauthorised
    const navigate = useNavigate();
    const ticketAccessRequired = (error) => {
        if(error?.status === 401) {
          setUnAuthorised(true);
          alert("Access Denied! You are not authorize to access this Ticket. Click on OK to redirect to the home page…");
            navigate('/datacenters');
        } else {
            console.log("error", error);
            api.trigger401(error, setErrormsg);
        }
    }

    useEffect(() => {
      if(!isOkAttachmentFetching && isOkAttachment && isOkAttachment.data){
        if(!testResult.value){
          let {result,message,updated_date} = isOkAttachment.data
          if(result){
            result=result.map(x=>x.replace(/[{}"]/g, ''))
            result=result.map(x=>x.replace(/\\n\s*/g, '\n'))
            setTestResult({value: result, show: true, updated_date: updated_date})
            formsubmit.setFieldsValue({"Comments":message})
          }
        }
      }
    }, [isOkAttachment,isOkAttachmentFetching])

    useEffect(() => {

      if(issue) {
        console.log(issue);
        if(Object.keys(issue.data).length > 0) setTodoSelected(issue.data);
        else {
          setErrormsg("There is no information available for this ticket. Most likely, the ticket is no longer in queue and the corresponding RMA order is closed.");
          setShowCollapse(false);
        }
      }

      fetchIsOkResultAttached(ticket_id);

      if(ticketOrders) {
        if(ticketOrders.data) {
          let rmaOrdersData = [];
          let serviceOrdersData = [];
          for(let order of ticketOrders.data) {
            if(order['order_type'] === 'Service Order') {
              serviceOrdersData.push(order)
            } else if(order['order_type'] === 'RMA Return') {
              rmaOrdersData.push(order);
            }
          }
          if(serviceOrdersData.length > 0) setSOOrders(serviceOrdersData);
          if(rmaOrdersData.length > 0) setRMAOrders(rmaOrdersData);
        }
      }

      if(error) {
        console.log(error);
        ticketAccessRequired(error);
      }

      if(FTNotesError) {
        console.log(FTNotesError);
        ticketAccessRequired(FTNotesError);
      }

      if(historyError) {
          console.log(historyError);
          ticketAccessRequired(historyError);
      }

      if(addResolutionError) {
        console.log(addResolutionError);
        ticketAccessRequired(addResolutionError);
      }

      if(trackingHistoryError) {
        console.log(trackingHistoryError)
        ticketAccessRequired(trackingHistoryError)
      }

    }, [issue, ticketOrders, error, FTNotesError, historyError, addResolutionError, trackingHistoryError]);

    const runHardwareTest = async() => {
      try {
          setErrormsg(null);
          startIsOkSpinner(true);
          if (!todoSelected.ip_asset_ip_address) {
              api.openNotification("Invalid IP!", "fail");
              return;
          }
          let payload = {
              "targets": [todoSelected.ip_asset_ip_address],
              "force": true,
              "triggered_by": "isokToDo",
              "ticket_id": todoSelected.issue_id
          }
          const resp = await triggerIsOk({ payload }).unwrap();
          sessionStorage.setItem(todoSelected.issue_id, resp.data.id);
          if(timer){
            clearInterval(timer);
          }
          timer = setInterval(() => {
            getAnswer();
          }, 25000)
          if(resp.data.id){
            api.openNotification("Success!", "success", "ISOK job submitted. Please wait for the results!");
          }
        } catch (error) {
          api.trigger401(error);
          startIsOkSpinner(false);
        }
      }

    const getAnswer = async () => {
      try{
        if(sessionStorage.getItem(todoSelected.issue_id)){
          let payload = {
            "id": sessionStorage.getItem(todoSelected.issue_id)
          }
          isokResponseData = await triggerGetResolution({ payload }).unwrap();
          if(isokResponseData.data.result.includes("INVALID") || isokResponseData.data.result.includes("COMPLETE") || isokResponseData.data.result.includes("FAILED")){
            startIsOkSpinner(false);
            api.openNotification("Success!", "success", "ISOK results are added in input section.");
            let result_detail = isokResponseData.data.resultDetail.map(x=>x.result.replace(/[{}"]/g, ''))
            setTestResult({value: result_detail, show: true});
            sessionStorage.removeItem(todoSelected.issue_id);
            if(isokResponseData.data.resultDetail){clearInterval(timer);}
          }
        }
      }
      catch (error){
        api.trigger401(error);
        clearInterval(timer);
        startIsOkSpinner(false);
        setTestResult({value: ''});
      }
    }

    const addResolution = async (values) => {
      try {
        setErrormsg(null);
        let payload;
        if (!values) return;
        startResolutionSpinner(true);
        if(todoSelected.delegated_status === "Hold - Batched H&E") {
          payload = {
            comments: values.Comments + " " || " ",
            issue_id: ticket_id,
            relatedActivityId: todoSelected.last_todo_act_id,
            username: userDetails?.name.toUpperCase(),
            return_tracking: values["Return Tracking"],
            resolution_type: (values["checkbox-group"] && values["checkbox-group"].length !== 0) ? values["checkbox-group"].join(", ") : "Other",
            problem_type:todoSelected.problem_type,
            resolution: values.Comments,
            timezone,
            updated_date: moment().format("YYYY-MM-DD"),
          };
          if (values["checkbox-group"] && values["checkbox-group"].length > 0) {
            if (payload.comments) {
              payload.comments += values["checkbox-group"].join(", ");
            } else {
              payload.comments = values["checkbox-group"].join(", ");
            }
          }
          if (values.HardwareTestResults && testResult.value){
            payload.comments += " ISOK results: " + testResult.value
          }
        } else {
          payload = {
            comments: values.Comments + " " || " ",
            issue_id: ticket_id,
            relatedActivityId: todoSelected.last_todo_act_id,
            username: userDetails?.name.toUpperCase(),
            return_tracking: values["Return Tracking"],
            resolution_type: "Other",
            problem_type:todoSelected.problem_type,
            resolution: values.Comments,
            timezone,
            updated_date: moment().format("YYYY-MM-DD"),
          };
        }
        console.log(payload, type, data_center_id);
          const resp = await triggerAddResolution({
            data_center_id,
            type,
            payload,
          }).unwrap();
          if (resp.success) {
            api.openNotification("Success!", "success", "Resolution Added");
            refetch();
            refetchHistory();
            formsubmit.resetFields();

          }
      } catch (error) {
        api.trigger401(error);
      } finally {
        startResolutionSpinner(false);
      }
    };

    const addIcon = (text) => {
      if(!text) return
      let icon;
      if (text.toLowerCase().includes("unknown")) {
        icon = <>
          {text + " "}
          <ExclamationCircleOutlined style={{ color: "#fadb14", fontSize: "125%" }}/>
        </>
      } else if (text.toLowerCase() === "no") {
        icon = <>
          {text + " "}
          <CloseCircleOutlined
            style={{ color: "#db0606", fontSize: "125%" }}
          />
        </>
      } else {
        icon = <>
          {text + " "}
          <CheckCircleOutlined
            style={{ color: "#52c41a", fontSize: "125%" }}
          />
        </>
      }
      return icon
    }
  
    const beautifyTestResult = (testResult) => {
      //here
      const items = testResult.map(result=>(<Descriptions.Item style={{whiteSpace: "pre-wrap"}} span={24} key={result}>{result}</Descriptions.Item>))
      return (<>{items}</>)
    }

    const renderTimeline = () => {
        let items=[];
        if(!isHistoryLoading && historyDetails){
          items = historyDetails.data.map(item => {
            return (
              <Timeline.Item key={item.created_date} color={item.node_type === "Todo"? "blue": "green"}>
              <p style={{fontWeight:500, color:"#1890ff"}}>{item.node_type} added by {item.created_by_login} on {item.created_date.substring(0,10)} at {item.created_date.substring(11,16)}</p>
              <p style={{ whiteSpace: "pre-line",backgroundColor: "rgb(148 147 146 / 21%)",padding: "15px",borderRadius: "8px", }}>{item.summary}</p>
            </Timeline.Item>)
          })
        }
        return(
                <>
                  {isHistoryFetching ? <AkamaiPreloader/>
                  :(
                    <Timeline>
                      {items.reverse()}
                    </Timeline>
                    )}
                </>
        )
      }

    const onFinishFailed = (errorInfo) => {
      console.log(errorInfo);
    };

    const add_FTNotes = async (values) => {
      try {
        let payload = {
          comment: values.Comment,
          ticket_id: todoSelected['issue_id'],
          added_by: userDetails.name
        }
        const resp = await triggerAddFTNotes({ payload }).unwrap();
        if (resp.success) {
          api.openNotification("Success!", "success", "Note added successfully");
          refetchNotes();
          formNotes.resetFields();
          setCommentDisabled(true);
        }
      } catch (error) {
        api.trigger401(error);
      }
    }

    const showDrawer = (text) => {
      triggerGetTrackingHistory({tracking_number: text})
      setSelectedTrackingNumber(text)
      setDrawerVisible(true)
    }
    const OrderColumns = [
      {
          title:"Tracking Number",
          key:"waybill_number",
          dataIndex:"waybill_number",
          render: (text) => (
              <Button type="link" onClick={()=>{showDrawer(text)}}>{text}</Button>
          ),
          sorter: (a,b) => a.issue_id.localeCompare(b.issue_id)
      },
      {
          title:"Product Name",
          key:"product_name",
          dataIndex:"product_name"
      },
      {
          title:"Quantity Requested",
          key:"quantity_requested",
          dataIndex:"quantity_requested"
      },
      {
        title:"Order Id",
        key:"order_id",
        dataIndex:"order_id"
      },
      {
        title:"Shipped S/N",
        key:"shipped_serial_no",
        dataIndex:"shipped_serial_no"
      },
  ]
  

    const renderDrawer = () =>{
      const title = `Tracking History - ${selectedTrackingNumber}`
      const drawerColumns = [
          {
              title:"Status",
              key:"STATUS",
              dataIndex:"STATUS"
          },
          {
              title:"Date",
              key:"CREATED_DATE",
              dataIndex:"CREATED_DATE",
              render: (text) => moment.utc(text).local().format("DD-MM-YYYY HH:mm:ss")
          }
      ]

      return(
          <Drawer
              title={title}
              placement="right"
              visible={drawerVisible}
              bodyStyle={{
                  minWidth: "320px",
              }}
              width={window.innerWidth > 600 ? "40%" : "100%"}
              maskClosable={false}
              onClose={()=>setDrawerVisible(false)}
          >
              <DataTables
                  columns={drawerColumns}
                  dataSource = {trackingHistory?.data}
                  rowkey="TRACKING_NUMBER"
                  emptyText="No tracking history to show"
                  loading={trackingHistoryIsFetching}
              />
          </Drawer>
      )
  }

    const renderOrders = (orders) => {
        return (
                  <DataTables dataSource={orders} columns={OrderColumns} rowKey="orders" size="small" loading={isTicketOrdersFetching} scroll={{x: "max-content"}} locale={{emptyText: "No Data"}} pagination={false}/>
              )
    }

    const renderForm = () => {
        return (
            
              <Form onFinish={addResolution} onFinishFailed={onFinishFailed}  form={formsubmit}>
              {(todoSelected.delegated_status === "Hold - Batched H&E") &&
                <Form.Item name="checkbox-group">
                  <Checkbox.Group>
                    <Row>
                      <Col span={8}>
                        <Checkbox
                          value="Rebooted"
                          style={{
                            lineHeight: "32px",
                          }}
                        >
                          Rebooted
                        </Checkbox>
                      </Col>
                      <Col span={8}>
                        <Checkbox
                          value="Rescued"
                          style={{
                            lineHeight: "32px",
                          }}
                        >
                          Rescued
                        </Checkbox>
                      </Col>
                      <Col span={8}>
                        <Checkbox
                          value="Replaced disk"
                          style={{
                            lineHeight: "32px",
                          }}
                        >
                          Replaced disk
                        </Checkbox>
                      </Col>
                      <Col span={8}>
                        <Checkbox
                          value="Reseated components"
                          style={{
                            lineHeight: "32px",
                          }}
                        >
                          Reseated components
                        </Checkbox>
                      </Col>
                      <Col span={8}>
                        <Checkbox
                          value="Replaced RAM"
                          style={{
                            lineHeight: "32px",
                          }}
                        >
                          Replaced RAM
                        </Checkbox>
                      </Col>
                      <Col span={8}>
                        <Checkbox
                          value="Replaced Machine"
                          style={{
                            lineHeight: "32px",
                          }}
                        >
                          Replaced Machine
                        </Checkbox>
                      </Col>
                      <Col span={8}>
                        <Checkbox
                          value="Identified Faulty disk"
                          style={{
                            lineHeight: "32px",
                          }}
                        >
                          Identified Faulty disk
                        </Checkbox>
                      </Col>
                      <Col span={8}>
                        <Checkbox
                          value="Replaced ETH/Power cable"
                          style={{
                            lineHeight: "32px",
                          }}
                        >
                          Replaced ETH/Power cable
                        </Checkbox>
                      </Col>
                      <Col span={8}>
                        <Checkbox
                          value="Clean/Replaced optic"
                          style={{
                            lineHeight: "32px",
                          }}
                        >
                          Clean/Replaced optic
                        </Checkbox>
                      </Col>
                      <Col span={8}>
                        <Checkbox
                          value="Other"
                          style={{
                            lineHeight: "32px",
                          }}
                        >
                          Other
                        </Checkbox>
                      </Col>
                    </Row>
                  </Checkbox.Group>
                </Form.Item>}
              {todoSelected.ip_asset_ip_address && (todoSelected.delegated_status === "Hold - Batched H&E") && (
                    <Descriptions.Item span={3} offset={4}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        style={{ marginRight: "30px", top: "-4px", display: "inline-flex", marginBottom: "10px"}}
                        onClick={() => runHardwareTest()}
                        disabled = {isokSpinner || isImpersonated}
                    >Run Hardware Test{isokSpinner && (<LoadingOutlined style={{ fontSize: 20, }} spin />)}</Button>
                    {/* <Badge status="success" text="Success"/> */}
                    </Descriptions.Item>)}
              {testResult.value && !isokSpinner && (todoSelected.delegated_status === "Hold - Batched H&E") && (<Row gutter={16}>
                          <Col span={24}>
                              <Form.Item name="HardwareTestResults" id="Test Results" label="Hardware Test Results" style={{
                                  flexDirection: "column",
                                  flexWrap: "wrap",
                                  textAlign: "left",
                                  marginBottom: "12px"
                              }} initialValue={testResult.value}>
      
                                <Descriptions colon={true} size="small" labelStyle={{ paddingRight:"0px"}} style={{backgroundColor:"#E8E8E8", padding:"5px 15px"}}>
                                  {beautifyTestResult(testResult.value)}
                                  {testResult.updated_date && <Descriptions.Item span={24} label="Last Updated Date" labelStyle={{fontWeight:500, fontSize:"12px", fontStyle:"italic"}} contentStyle={{fontWeight:500, fontSize:"12px", fontStyle:"italic"}}>{testResult.updated_date}</Descriptions.Item>}
                                </Descriptions>
                              </Form.Item>
                          </Col>
                      </Row>)}
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    name="Return Tracking"
                    label="Return Tracking"
                    id="Return Tracking"
                    style={{
                      flexDirection: "column",
                      flexWrap: "wrap",
                      textAlign: "left",
                    }}
                    rules={type==="todos"?([{whitespace: true}]):([{whitespace: true},{ required: true, message: "Return tracking is required!"}])}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                    <div style={{float:"right", position:"absolute", right:0, zIndex:10}}>
                      <Button type='link' onClick={()=>{setChildDrawerNotesVisible(true)}} style={{padding:"0px"}}>
                        <Badge
                          count={isFTNotesFetching ? <ClockCircleOutlined style={{  color: '#f5222d'}}/> : FTNotes?.data.length }
                        >
                            <div style={{color: "#1890ff"}}>
                              Add Notes&nbsp;&nbsp;&nbsp;
                            </div>
                        </Badge>
                      </Button>
                    </div>
                  <Form.Item
                    name="Comments"
                    label="Comment"
                    style={{
                      flexDirection: "column",
                      flexWrap: "wrap",
                      textAlign: "left",
                      paddingBottom: 12
                    }}
                    rules={[{ required: true, message: "Comment is required!"},{whitespace: true, message: "Comment is required!"}]}
                  >
                    <Input.TextArea rows={3} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col style={{ maxWidth: "none" }}>
                  <Form.Item>
                    {!resolutionSpinner && (
                      <Button
                        type="primary"
                        htmlType="submit"
                        disabled={(!testResult.value && isokSpinner) || isImpersonated}
                        style={{display: "inline-flex" }}>
                        Submit
                      </Button>
                    )}
                    {resolutionSpinner &&  (
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{display: "inline-flex" }}
                      disabled = {true}
                    >Submit{resolutionSpinner && <LoadingOutlined style={{ fontSize: 20,color:'grey',marginBottom:2 }} spin />}</Button>
                    )
                    }
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          );
      };


    const renderNotesForm = () => {
      return (
        <Form onFinish={add_FTNotes} form={formNotes}
        >
          <Form.Item
                name="Comment"
                label="Comment"
                style={{
                  flexDirection: "column",
                  flexWrap: "wrap",
                  textAlign: "left",
                  paddingBottom: 12
                }}
                rules={[{}, {
                  validator(rules, value){
                    if(!value){
                      setCommentDisabled(true);
                    }
                    else if(!value.trim()?.length){
                      if(!commentDisabled) setCommentDisabled(true);
                      return Promise.reject(
                        new Error("Comments are required!")
                      )
                    }
                    else{
                      if(commentDisabled) setCommentDisabled(false)
                    }
                    if(value?.length >= 2000){
                      return Promise.reject(
                        new Error("Comments cannot exceed 2000 characters!")
                      )
                    }
                  return Promise.resolve()
                }
                }]}
              >
                <Input.TextArea rows={3} maxLength='2000' showCount />
              </Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{display: "inline-flex" }}
                disabled = {commentDisabled || isImpersonated}
                loading= {addFTNotes?.isLoading}
                >Submit</Button>
        </Form>
      )
    }
      
    const renderFTNotesChildDrawer = () => {
      let comment;
      if(!isFTNotesLoading && FTNotes && FTNotes.data.length !== 0){
        comment = <List
        className="comment-list"
        header={`${FTNotes.data.length} comments`}
        itemLayout="horizontal"
        dataSource={FTNotes.data}
        renderItem={item => {
          return (
          <li>
            <Comment
              avatar={<Avatar style={{ backgroundColor: '#67b2f7db', verticalAlign: 'middle' }} size="medium" gap={1}>{item.added_by ? item.added_by[0] : "!"}</Avatar>}
              author={<div style={{fontWeight: 500, color: "rgb(24, 144, 255)"}}>{item.added_by}</div>}
              content={<div style={{whiteSpace: "pre-line", backgroundColor: "rgba(148, 147, 146, 0.21)", padding: "10px", borderRadius: "8px"}}>{item.comment}</div>}
              datetime={<div style={{fontWeight: 500, color: "rgb(24, 144, 255)"}}>{moment.utc(item.added_date).local().format("ddd, DD MMM YYYY HH:mm")}</div>}
            />
          </li>
          )}}
        />
      }
      return(
        <>
            <Drawer
              title="Notes"
              placement="right"
              width={window.innerWidth > 600 ? "500" : "100%"}
              onClose={()=>{setChildDrawerNotesVisible(false)}}
              visible={childDrawerNotesVisible}
            >
              <Alert
              banner
              message={
                <Marquee pauseOnHover gradient={false}>
                <strong>Note:&nbsp;</strong>This information can only be viewed by the Field Technician who have access to the Ticket id &nbsp;
                </Marquee>
              }
            />
              {
                isFTNotesFetching ? <AkamaiPreloader/>
                :comment
              }
            {renderNotesForm()}
            </Drawer>
            </>
      )
    }
    
    return (
        <>
            
              <Header title="Ticket Details" />
              {errorMsg && <Banner msg={errorMsg} type="error" />}
              {!isFetching && todoSelected && (todoSelected.is_safe === "Unknown Ask" || todoSelected.is_safe === "No") && <Banner msg={safeBadge} type="warning" />}
              <Row>
                  <Col lg={18} md={14} xs={24}>
                      <Breadcrumbs />
                  </Col>
              </Row>
              <Divider />
              {!unAuthorised && showCollapse && 
              (isFetching ? <AkamaiPreloader /> :
              (<div className="content-container">
                  <Collapse size="large" defaultActiveKey={['1', '2', '3']}>
                      <Panel header={<b>Data Center Info</b>} key="1">
                        <Descriptions bordered size="small" labelStyle={{ paddingRight:"0px", fontWeight:'bold'}}>
                          <Descriptions.Item label="Data Center ID" span={3}>{ isFetching ? "Loading..." : (todoSelected.data_center_id) || "N/A"}</Descriptions.Item>
                          <Descriptions.Item label="Data Center Name" span={3}>{ isFetching ? "Loading..." : (todoSelected.data_center_name) || "N/A"}</Descriptions.Item>
                          <Descriptions.Item label="Data Center Location" span={3}>{ isFetching ? "Loading..." : (todoSelected.data_center_location) || "N/A"}</Descriptions.Item>
                          <Descriptions.Item label="Data Center Instructions" span={3}>{ isFetching ? "Loading..." : (todoSelected.data_center_instructions) || "N/A"}</Descriptions.Item>
                        </Descriptions>
                      </Panel>
                      <Panel header={<b>Ticket Info</b>}  key="2">
                        <Descriptions bordered size="small" labelStyle={{ paddingRight:"0px", fontWeight:'bold'}}>
                          <Descriptions.Item label="Ticket ID" span={3}>{ isFetching ? "Loading..." : (todoSelected.issue_id) || "N/A"}</Descriptions.Item>
                          <Descriptions.Item label="Problem Type" span={1}>{ isFetching ? "Loading..." : (todoSelected.problem_type) || "N/A"}</Descriptions.Item>
                          <Descriptions.Item label="Sub Type" span={2}>{ isFetching ? "Loading..." : (todoSelected.sub_type) || "N/A"}</Descriptions.Item>
                          <Descriptions.Item label="Priority" span={3}>{ isFetching ? "Loading..." : (todoSelected.priority) || "N/A"}</Descriptions.Item>
                          <Descriptions.Item label="DP name" span={1}>{ isFetching ? "Loading..." : (todoSelected.deployment_project_name) || "N/A"}</Descriptions.Item>
                          <Descriptions.Item label="Delegated status" span={2}>{ isFetching ? "Loading..." : (todoSelected.delegated_status) || "N/A"}</Descriptions.Item>
                        </Descriptions>
                      </Panel>
                      <Panel header={<b>Machine Info</b>}  key="3">
                        <Descriptions bordered size="small" labelStyle={{ paddingRight:"0px", fontWeight:'bold'}}>
                          <Descriptions.Item label="Safe" span={3}>{ isFetching ? "Loading..." : addIcon(todoSelected.is_safe) || "N/A"}</Descriptions.Item>
                          <Descriptions.Item span={3} label="Rack Number">{ isFetching ? "Loading..." : (todoSelected.rack_number || "NA")}</Descriptions.Item>
                          <Descriptions.Item span={3} label="Current Hardware Serial Number">{ isFetching ? "Loading..." : (todoSelected.current_hardware_serial_number) || "NA"}</Descriptions.Item>
                          <Descriptions.Item span={3} label="Hardware Asset Serial Number">{ isFetching ? "Loading..." : (todoSelected.hardware_asset_serial_number) || "NA"}</Descriptions.Item>
                          <Descriptions.Item span={3} label="Machine Number">{ isFetching ? "Loading..." : (todoSelected.hardware_asset_machine_number) || "NA"}</Descriptions.Item>
                          <Descriptions.Item span={3} label="Asset Status">{ isFetching ? "Loading..." : (todoSelected.hardware_asset_status) || "NA"}</Descriptions.Item>
                          <Descriptions.Item span={3} label="IP Address">{ isFetching ? "Loading..." : (todoSelected.ip_asset_ip_address) || "NA" }</Descriptions.Item>
                          <Descriptions.Item span={1} label="NetMask">{ isFetching ? "Loading..." : (todoSelected.net_mask) || "NA"}</Descriptions.Item>
                          <Descriptions.Item span={2} label="Gateway">{ isFetching ? "Loading..." : (todoSelected.gateway) || "NA"}</Descriptions.Item>
                        </Descriptions>
                      </Panel>
                      <Panel header={<b>Activities</b>} key="4">
                          {
                            (isFetching || isHistoryFetching)? <AkamaiPreloader />
                            :
                            <div style={{padding: "10px"}}>{renderTimeline()}</div>}
                      </Panel>
                      <Panel header={<b>Resolutions</b>} key="6">
                          <>
                          {
                            isFetching ? <AkamaiPreloader /> :
                            (Object.keys(todoSelected).length > 0) && renderForm()
                          }
                          </>
                      </Panel>
                      {/* <Panel header={<b>Shipments</b>} key="7">
                          <>
                          {
                            isShipmentFetching ? <AkamaiPreloader /> :
                            rendershipmentinfo()
                          }
                          </>
                      </Panel> */}
                      <Panel header={<b>Service Orders</b>}  key="8">
                        {
                          (isTicketOrdersFetching || isFetching) ? <AkamaiPreloader />
                          :
                          renderOrders(soOrders)
                        }
                      </Panel>
                      <Panel header={<b>RMA Orders</b>}  key="9">
                        {
                          (isTicketOrdersFetching || isFetching) ? <AkamaiPreloader />
                          :
                          renderOrders(rmaOrders)
                        }
                      </Panel>
                  </Collapse>
                  { (type === "todos") && childDrawerNotesVisible && renderFTNotesChildDrawer()}
                  {drawerVisible && renderDrawer()}
              </div>
              ))
            }
        </>
    );
}

export default function TicketDetails( { type, isImpersonated } ) {
    let props = { type, isImpersonated };
    return BaseLayout(TicketDetailsWrapper, props);
}